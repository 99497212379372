import  MenuLog  from './menu.js'
import "./App.css";
import { Carousel } from "./lib";

function galeria() {
  const data = [
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/1.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/2.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/3.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/4.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/5.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/6.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/7.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/8.JPG",
      caption: "<div></div>",
    },

    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/9.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/10.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/11.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/12.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/13.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/14.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/15.JPG",
      caption: "<div></div>",
    },
{
      image:
        "https://puertadehierroac.mx/imagenes/assets/16.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/17.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/18.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/19.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/20.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/21.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/22.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/23.JPG",
      caption: "<div></div>",
    },
    {
      image:
        "https://puertadehierroac.mx/imagenes/assets/24.JPG",
      caption: "<div></div>",
    },{
      image:
        "https://puertadehierroac.mx/imagenes/assets/25.JPG",
      caption: "<div></div>",
    },{
      image:
        "https://puertadehierroac.mx/imagenes/assets/26.JPG",
      caption: "<div></div>",
    },{
      image:
        "https://puertadehierroac.mx/imagenes/assets/27.JPG",
      caption: "<div></div>",
    },{
      image:
        "https://puertadehierroac.mx/imagenes/assets/28.JPG",
      caption: "<div></div>",
    },


  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <div className="galeria">

           <p clas="bg-darkgrey"><MenuLog/></p>

      <div style={{ textAlign: "center" }}>
        <h2>Galeria Puerta de Hierro</h2>
        <div
          style={{
            padding: "0 10px",
          }}
        >
          <Carousel
            data={data}
            time={2500}
            width="1250px"
            height="550px"
            captionStyle={captionStyle}
            radius="2px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={false}
            pauseIconColor="white"
            pauseIconSize="20px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            showNavBtn={true}
            style={{
              textAlign: "center",
              maxWidth: "1250px",
              height: "50px",
              margin: "10px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default galeria;
